import React, { useRef, useCallback, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const BlogSection = ({ blogPosts }) => {
  const blogRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const scrollContainer = useCallback((direction) => {
    if (blogRef.current) {
      let newIndex;
      if (isMobile) {
        newIndex = Math.max(0, Math.min(currentIndex + direction, blogPosts.length - 1));
      } else {
        newIndex = Math.max(0, Math.min(currentIndex + direction * 3, Math.max(0, blogPosts.length - 3)));
      }
      setCurrentIndex(newIndex);
    }
  }, [currentIndex, blogPosts.length, isMobile]);

  return (
    <section id="blog" className="py-20 bg-gradient-to-br from-gray-800 to-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-yellow-400 opacity-5 pattern-diagonal-lines-sm"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="border-2 border-orange-400 rounded-lg p-4 md:p-12">
          <h2 className="text-4xl font-bold mb-12 text-center text-white">Latest from Our Blog</h2>
          <div className="relative">
            <div ref={blogRef} className="overflow-hidden">
              <motion.div 
                className="flex transition-all duration-300 ease-in-out"
                style={{ 
                  width: isMobile ? '100%' : `${Math.ceil(blogPosts.length / 3) * 100}%`,
                  transform: isMobile 
                    ? `translateX(-${currentIndex * 100}%)` 
                    : `translateX(-${(currentIndex / 3) * 100}%)`
                }}
              >
                {blogPosts.map((post, index) => (
                  <motion.div
                    key={post.id}
                    className={`${isMobile ? 'w-full' : 'w-1/3'} flex-shrink-0 px-2 md:px-4`}
                  >
                    <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden h-full">
                      <div className="h-48 bg-cover bg-center" style={{ backgroundImage: `url(${post.image})` }}></div>
                      <div className="p-6">
                        <h3 className="text-xl font-bold mb-2 text-white">{post.title}</h3>
                        <p className="text-gray-400 mb-4">{post.description}</p>
                        <Link to={post.link} className="text-yellow-400 hover:text-yellow-300 transition duration-300 font-semibold">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
            <button 
              className="absolute top-1/2 -left-4 transform -translate-y-1/2 bg-yellow-400 text-gray-900 p-2 rounded-full shadow-md hover:bg-yellow-300 transition duration-300" 
              onClick={() => scrollContainer(-1)}
              disabled={currentIndex === 0}
            >
              <ChevronLeft size={24} />
            </button>
            <button 
              className="absolute top-1/2 -right-4 transform -translate-y-1/2 bg-yellow-400 text-gray-900 p-2 rounded-full shadow-md hover:bg-yellow-300 transition duration-300" 
              onClick={() => scrollContainer(1)}
              disabled={isMobile ? currentIndex === blogPosts.length - 1 : currentIndex >= blogPosts.length - 3}
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;