import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Lightbulb, Code, Palette, TrendingUp } from 'lucide-react';

const divisions = [
  { icon: <Lightbulb size={32} />, title: "Idea Validation", description: "We validate your business idea through deep market research and user surveys.", gradient: "from-orange-300 to-orange-600" },
  { icon: <Palette size={32} />, title: "Design & UX", description: "We create intuitive, user-centric designs that elevate your product.", gradient: "from-orange-300 to-orange-600" },
  { icon: <Code size={32} />, title: "Development", description: "Our expert team brings your idea to life with cutting-edge technology.", gradient: "from-orange-300 to-orange-600" },
  { icon: <TrendingUp size={32} />, title: "Growth Strategy", description: "We develop strategies to launch and scale your product effectively.", gradient: "from-orange-300 to-orange-600" }
];

const DRFTDivisions = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <section ref={ref} className="py-24 bg-gray-900">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold mb-16 text-center text-white">Our DRFT Divisions</h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate={controls}
        >
          {divisions.map((division, index) => (
            <motion.div
              key={index}
              className={`bg-gradient-to-br ${division.gradient} p-1 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300`}
              variants={itemVariants}
            >
              <div className="bg-gray-900 p-6 rounded-2xl h-full flex flex-col">
                <div className="text-white mb-4 flex items-center justify-center w-16 h-16 bg-opacity-20 bg-white rounded-full">
                  {division.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3 text-white">{division.title}</h3>
                <p className="text-gray-300 flex-grow">{division.description}</p>
                <motion.div 
                  className="mt-4 text-sm font-semibold text-white cursor-pointer"
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  Learn More →
                </motion.div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default DRFTDivisions;
