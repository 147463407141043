import React from 'react';
import { Link } from 'react-router-dom';
import { X, Linkedin, MessageCircle } from 'lucide-react';
import DrftLogo from '../Images/DrftLogo.png';

const Layout = ({ children }) => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col">
      <header className="py-1 sm:py-6 relative">
        <div className="container mx-auto flex justify-between items-center px-4">
          <Link to="/" className="flex items-center absolute left-4 top-1/2 transform -translate-y-1/2">
            <img 
              src={DrftLogo} 
              alt="drft.studio logo" 
              className="h-16 sm:h-36 w-auto -my-6 sm:-my-14" // Kept large size, added negative margin
            />
          </Link>
          <nav className="ml-auto"> {/* Pushed nav to the right */}
            <ul className="flex space-x-4 sm:space-x-6">
              <li><Link to="/" className="text-sm sm:text-base hover:text-yellow-400">Home</Link></li>
              <li><Link to="/about" className="text-sm sm:text-base hover:text-yellow-400">About</Link></li>
              <li><Link to="/get-in-touch" className="text-sm sm:text-base hover:text-yellow-400">Get in Touch</Link></li>
            </ul>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        {children}
      </main>

      <footer className="bg-gray-800 py-12 sm:py-16">
  <div className="container mx-auto px-4">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
      <div className="flex flex-col items-start"> {/* Added flex-col and items-start */}
        <Link to="/" className="inline-block mb-8 -ml-2 sm:-ml-14"> {/* Added negative left margin */}
          <img 
            src={DrftLogo} 
            alt="drft.studio logo" 
            className="h-16 sm:h-36 w-auto -my-6 sm:-my-14"
          />
        </Link>
        <p className="text-gray-400 mb-12">Transforming ideas into successful digital products.</p>
        <div className="flex space-x-4">
          <X size={20} className="text-gray-400 hover:text-yellow-400 cursor-pointer" />
          <Linkedin size={20} className="text-gray-400 hover:text-yellow-400 cursor-pointer" />
          <MessageCircle size={20} className="text-gray-400 hover:text-yellow-400 cursor-pointer" />
        </div>
      </div>
      <div>
        <h3 className="text-lg font-bold text-white mb-4">Quick Links</h3>
        <ul className="space-y-2">
          <li><Link to="/" className="text-gray-400 hover:text-yellow-400">Home</Link></li>
          <li><Link to="/about" className="text-gray-400 hover:text-yellow-400">About</Link></li>
          <li><Link to="/#our-drfts" className="text-gray-400 hover:text-yellow-400">Our DRFTs</Link></li>
          <li><Link to="/get-in-touch" className="text-gray-400 hover:text-yellow-400">Get in Touch</Link></li>
        </ul>
      </div>
      <div>
        <h3 className="text-lg font-bold text-white mb-4">Contact Us</h3>
        <p className="text-gray-400 mb-2">hello@drft.studio</p>
        <p className="text-gray-400 mb-4">+27 711-055-400</p>
        <Link to="/get-in-touch" className="bg-white text-gray-900 px-4 py-2 rounded-full font-bold hover:bg-[#F15A22] transition duration-300 text-sm sm:text-base">
          Get in Touch
        </Link>
      </div>
    </div>
    <div className="border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
      <p className="text-gray-400 mb-4 md:mb-0 text-sm sm:text-base">&copy; 2024 DRFT Studios. All rights reserved.</p>
      <div className="flex space-x-4">
        <Link to="/privacy-policy" className="text-gray-400 hover:text-yellow-400 text-sm sm:text-base">Privacy Policy</Link>
        <Link to="/terms-of-service" className="text-gray-400 hover:text-yellow-400 text-sm sm:text-base">Terms of Service</Link>
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default Layout;