import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ChevronUp, ChevronDown, X, Linkedin, MessageCircle, Flag, Rocket, Star, Quote, Search, ChevronLeft, ChevronRight, TrendingUp } from 'lucide-react';
import EpicTimeline from './EpicTimeline';
import DRFTDivisions from './DRFTDivisions';
import InfographicSection from './InfographicSection';
import BlogSection from './BlogSection';
import blogimageone from './Images/blog-one-image.png';
import blogimagetwo from './Images/blog-two-image.png';
import blogimagethree from './Images/blog-three-image.png';
import DrftLogo from './Images/DrftLogo.png';

// Animated Logo Component
const AnimatedLogo = () => {
  const logoVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <motion.div
      variants={logoVariants}
      initial="hidden"
      animate="visible"
      className="w-96 h-96" // Increased size
    >
      <DRFTLogo className="w-full h-full" /> 
    </motion.div>
  );
};

// Testimonial Card Component
const TestimonialCard = ({ testimonial }) => (
  <motion.div 
    className="bg-gray-800 p-6 rounded-lg shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300, damping: 10 }}
  >
    <Quote className="text-yellow-400 mb-4" size={32} />
    <p className="text-white mb-4">{testimonial.content}</p>
    <div className="flex items-center justify-between">
      <div>
        <h4 className="text-yellow-400 font-bold">{testimonial.name}</h4>
        <p className="text-gray-400">{testimonial.company}</p>
      </div>
      <div className="flex">
        {[...Array(testimonial.rating)].map((_, i) => (
          <Star key={i} className="text-yellow-400" size={16} fill="#FBBF24" />
        ))}
      </div>
    </div>
  </motion.div>
);

// FAQ Item Component
const FAQItem = ({ question, answer, isOpen, toggleFAQ }) => (
  <motion.div
    className="bg-gray-800 rounded-lg overflow-hidden mb-4"
    initial={false}
    animate={{ backgroundColor: isOpen ? "#1F2937" : "#1E293B" }}
    transition={{ duration: 0.3 }}
  >
    <motion.button
      className="flex justify-between items-center w-full p-4 text-left focus:outline-none"
      onClick={toggleFAQ}
      whileHover={{ backgroundColor: "#374151" }}
      transition={{ duration: 0.2 }}
    >
      <span className="font-semibold text-lg text-white">{question}</span>
      <motion.div
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {isOpen ? <ChevronUp className="text-yellow-400" /> : <ChevronDown className="text-yellow-400" />}
      </motion.div>
    </motion.button>
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="p-4 pt-6 text-gray-300">
            <p>{answer}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);

// Main DRFTStudiosHomepage Component
const DRFTStudiosHomepage = () => {
  // State declarations
  const [openFAQ, setOpenFAQ] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFAQs, setFilteredFAQs] = useState([
    { question: "What is the best way to contact DRFT?", answer: "The best way to reach us is through our Contact Us page. Once you submit your inquiry, we will provide a form to gather more details about your business or project. After reviewing your information, we will arrange a meeting to discuss potential next steps and how we can collaborate effectively." },
    { question: "How long does the development process take?", answer: "The development timeline varies depending on the project scope. Before full-scale development, we ensure your concept is market-ready. We also create a semi-technical MVP to test key features with real users, helping you refine your product before moving to the next stage. Once your idea is validated, we focus on building a scalable, secure application with full-stack development, incorporating all validated features. Our team provides quality assurance, testing, and support throughout deployment and launch." },
    { question: "Do you work with established businesses or only startups?", answer: "While our expertise lies in helping startups, we also work with established businesses seeking to innovate, launch new products, or revitalize their platforms. In addition, we offer growth strategies and complete UX/UI redesigns to enhance user experience and drive business success." }
  ]);

  // Refs and Hooks
  const successStoriesRef = useRef(null);
  const blogRef = useRef(null);
  const location = useLocation();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  // Effects
  useEffect(() => {
    const hasVisitedBefore = sessionStorage.getItem('hasVisitedHomepage');
    
    if (!hasVisitedBefore || location.state?.forceAnimation) {
      setShowAnimation(true);
      const timer = setTimeout(() => {
        setShowContent(true);
        sessionStorage.setItem('hasVisitedHomepage', 'true');
      }, 3500);
      return () => clearTimeout(timer);
    } else {
      setShowContent(true);
    }
  }, [location]);

  useEffect(() => {
    if (inView) {
      console.log("Our DRFTs section is in view");
      controls.start('visible');
    } else {
      console.log("Our DRFTs section is out of view");
      controls.start('hidden');
    }
  }, [controls, inView]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredFAQs(filteredFAQs);
    } else {
      const filtered = filteredFAQs.filter(faq => 
        faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredFAQs(filtered);
    }
  }, [searchTerm]);

  // Helper functions
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const scrollContainer = (container, direction) => {
    const scrollAmount = container.current.offsetWidth;
    const newScrollPosition = container.current.scrollLeft + direction * scrollAmount;
    container.current.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    });
  };

  // Animation variants
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 1.2,
        ease: "easeOut"
      }
    }
  };

  // Blog posts data
  const blogPosts = [
    {
      id: 1,
      title: "The Crucial Decision for Early-Stage Founders: To Raise Capital or Not?",
      description: "Explore the pros and cons of raising capital versus bootstrapping for early-stage startups.",
      image: blogimageone,
      link: "/blog/raise-capital-or-not"
    },
    {
      id: 2,
      title: "Idea Validation for Startups: Why It's the First Crucial Step",
      description: "For early-stage founders, idea validation is the most critical step to ensure your startup has a real shot at success.",
      image: blogimagetwo,
      link: "/blog/idea-validation-for-startups"
    },
    {
      id: 3,
      title: "Building a Brand Identity That Stands Out in a Crowded Market",
      description: "In today's competitive landscape, crafting a compelling brand identity is crucial for startups to differentiate themselves and capture the attention of their target audience.",
      image: blogimagethree,
      link: "/blog/importance-of-brand-identity"
    },
  ];

// Component JSX
return (
  <div className="flex flex-col min-h-screen bg-[#082340]">
    <AnimatePresence>
      {showAnimation && !showContent && (
        <motion.div
          key="logo"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.8 } }}
          className="absolute inset-0 flex items-center justify-center bg-[#082340] z-50"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="w-full max-w-2xl px-4"
          >
            <img src={DrftLogo} alt="DRFT Studio Logo" className="w-full h-auto" />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>

    <motion.div
      initial="hidden"
      animate={showContent ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.8 } },
      }}
    >
    {/* Hero Section */}
    <section className="hero relative py-8 sm:py-12 text-center min-h-screen flex flex-col items-center justify-center bg-[#082340]">
      <div className="absolute inset-0 bg-[#082340] opacity-100"></div>
      <div className="container mx-auto relative z-10 max-w-4xl flex flex-col items-center">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="mb-0 w-full max-w-3xl"
        >
          <img src={DrftLogo} alt="DRFT Studio Logo" className="w-full h-auto" />
        </motion.div>
        <motion.p
          className="text-xl mb-8 text-white max-w-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          Transforming Early-Stage Ideas Into Reality.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.2, duration: 0.8 }}
        >
          <Link
            to="/get-in-touch"
            className="bg-white text-gray-900 px-8 py-3 rounded-full font-bold hover:bg-[#F15A22] transition duration-300 text-base inline-block"
          >
            Get in Touch
          </Link>
        </motion.div>
      </div>
      <motion.div
        className="flex justify-center space-x-8 mt-auto pb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.6, duration: 0.8 }}
      >
        <X size={24} className="text-white opacity-70 hover:opacity-100 transition-opacity" />
        <Linkedin size={24} className="text-white opacity-70 hover:opacity-100 transition-opacity" />
        <MessageCircle size={24} className="text-white opacity-70 hover:opacity-100 transition-opacity" />
      </motion.div>
    </section>

        {/* Our DRFTs Section */}
        <section id="our-drfts" className="py-20 bg-gray-900" ref={ref}>
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-12 text-center">Our DRFTs</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              {[
                { icon: <Flag size={48} />, title: "Phase 1: Idea Validation", subtitle: "Transform Your Idea into Reality", description: "We rigorously validate your business idea through market research, user surveys, and interviews. By the end, you'll have a refined MVP that validates your concept and is aligned with your audience, setting the stage for full development.", footer: "We minimize risks and set the stage for your idea to succeed.", color: "from-orange-300 to-orange-600" },
                { icon: <Rocket size={48} />, title: "Phase 2: App Development & Design", subtitle: "From Concept to Market-Ready Product", description: "We build custom applications and platforms using diverse coding languages and frameworks, delivering seamless design, robust functionality, and scalable technology.", footer: "From concept to deployment, we manage the technical complexities, empowering you to drive growth and focus on your business.", color: "from-purple-500 to-pink-500" },
              ].map((drft, index) => (
                <motion.div
                  key={index}
                  variants={cardVariants}
                  initial="hidden"
                  animate={controls}
                  transition={{ delay: index * 0.2 }}
                  className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300"
                >
                  <div className={`h-2 bg-gradient-to-r ${drft.color}`} />
                  <div className="p-6 relative">
                    <div className="flex items-center mb-4">
                      <div className={`text-${drft.color.split('-')[1]} mr-4`}>{drft.icon}</div>
                      <div>
                        <h3 className="text-2xl font-bold">{drft.title}</h3>
                        <h4 className="text-xl text-gray-300">{drft.subtitle}</h4>
                      </div>
                    </div>
                    <p className="mb-4">{drft.description}</p>
                    <p className="text-sm text-gray-400">{drft.footer}</p>
                  </div>
                </motion.div>
              ))}
            </div>
            <motion.div
              variants={cardVariants}
              initial="hidden"
              animate={controls}
              transition={{ delay: 0.4 }}
              className="flex justify-center"
            >
              <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 max-w-2xl w-full">
                <div className="h-2 bg-gradient-to-r from-blue-500 to-cyan-500" />
                <div className="p-6 relative">
                  <div className="flex items-center mb-4">
                    <div className="text-green-500 mr-4"><TrendingUp size={48} /></div>
                    <div>
                      <h3 className="text-2xl font-bold">Phase 3: Launch & Growth</h3>
                      <h4 className="text-xl text-gray-300">From Market Entry to Expansion</h4>
                    </div>
                  </div>
                  <p className="mb-4">We drive your app's success with comprehensive marketing strategies, digital campaigns, and user acquisition optimization. </p>
                  <p className="text-sm text-gray-400">We provide ongoing technical support and feature additions while planning for market expansion, ensuring sustained growth and success.</p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>



<EpicTimeline />

{/* DRFT Divisions Section */}
<DRFTDivisions />

<InfographicSection />
  {/* Infographic Section */}
        
{/* Ready to take the drift? Section */}
<section id="get-in-touch" className="py-24 bg-gradient-to-br from-gray-900 to-gray-800 relative overflow-hidden">
          <div className="container mx-auto px-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="bg-gray-800 bg-opacity-80 backdrop-filter backdrop-blur-sm rounded-2xl shadow-2xl p-12 max-w-4xl mx-auto"
            >
              <h2 className="text-5xl font-bold mb-6 text-center text-white">Ready to take the drift?</h2>
              <p className="text-xl mb-10 text-center text-gray-300">Join us on an epic journey to transform your ideas into reality.</p>
              <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full md:w-auto px-6 py-3 rounded-full bg-gray-700 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                />
                <Link
                  to="/get-in-touch"
                  className="w-full md:w-auto bg-white text-gray-900 px-8 py-3 rounded-full font-bold hover:bg-[#F15A22] transition duration-300 text-center"
                >
                  Start Your Journey
                </Link>
              </div>
            </motion.div>
          </div>
          
          {/* Background animation */}
          <div className="absolute inset-0">
            <svg className="absolute bottom-0 left-0 w-full" viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
              <motion.path 
                fill="#4B5563" 
                fillOpacity="0.2"
                d="M0,128L48,144C96,160,192,192,288,197.3C384,203,480,181,576,181.3C672,181,768,203,864,208C960,213,1056,203,1152,170.7C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                animate={{
                  d: [
                    "M0,128L48,144C96,160,192,192,288,197.3C384,203,480,181,576,181.3C672,181,768,203,864,208C960,213,1056,203,1152,170.7C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
                    "M0,64L48,85.3C96,107,192,149,288,160C384,171,480,149,576,128C672,107,768,85,864,96C960,107,1056,149,1152,165.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 20,
                  ease: "easeInOut",
                }}
              />
            </svg>
          </div>
        </section>

  
      {/* Blog Section */}
      <BlogSection blogPosts={blogPosts} />
        

      {/* FAQ Section */}
      <section id="faq" className="container mx-auto py-20 px-4">
          <motion.h2 
            className="text-4xl font-bold mb-12 text-center text-white"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Frequently Asked Questions
          </motion.h2>
          <motion.div 
            className="max-w-3xl mx-auto mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="relative">
              <input
                type="text"
                placeholder="Search FAQs..."
                className="w-full p-3 pl-10 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </motion.div>
          <motion.div 
            className="max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {filteredFAQs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openFAQ === index}
                toggleFAQ={() => toggleFAQ(index)}
                className="mb-4" // Add margin bottom to each FAQ item
              />
            ))}
          </motion.div>
        </section>


        {/* Floating scroll-to-top button */}
        <motion.div
          className="fixed bottom-8 right-8 z-50"
          animate={{ y: [0, -10, 0] }}
          transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
        >
          <button 
            className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:bg-yellow-300 transition-colors duration-300" 
            onClick={scrollToTop}
            aria-label="Scroll to top"
          >
            <ChevronUp size={24} className="text-gray-900" />
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default DRFTStudiosHomepage;